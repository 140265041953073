<template>
  <div>
    <div class="full-width category-container m-t-36">
      <div class="container">
        <Breadcrumbs :path="path" />
      </div>
    </div>
    <div class="full-width category-container">
      <div class="container">
        <div class="col-7 product-images">
          <!--<img :src="'http://shop.altromercato.it/products/' + images[0]" class="main-image" />-->

          <h2 class="neue only-mobi prod-name-mobi"></h2>

          <div class="obj-marks">
            <img src="https://shop.altromercato.it/products/00003459_1.jpg?v=2" class="main-image" />
            <p class="text-small mw-487 text-right m-0">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto debitis accusamus id reiciendis odio quia sunt molestias vero, maiores eligendi similique facere aliquam quibusdam cumque commodi? Consectetur eligendi quia repudiandae.</p>
          </div>

        </div>
        <div class="col-5 product-content">
          <h2 class="neue text-25">Nome del set prodotto abbigliamento</h2>
          <div style="width:100%;text-align:right;"><span class="neue text-thin cursor-pointer text-underlined text-verySmall">Guida alle taglie</span></div>
          <div class="flex flex-col items">
            <div class="item">
              <div class="left">
                <img src="https://shop.altromercato.it/products/00003459_1.jpg?v=2" width="299px"/>
              </div>
              <div class="right">
                <h2 class="neue text-18">Nome del set prodotto abbigliamento</h2>
                <div class="price">
                  <div class="price col-4">
                    <span class="cart-discount" v-if="item.sconto > 0">&euro; {{($utils.getProductPrice(item, true).toFixed(2) + "").replace('.', ',')}} <b style="display: inline-block; margin-left: 5px">-{{item.sconto}}%</b></span>
                    <span class="current">&euro; 43,00</span>
                    <span class="online-price">Prezzo online</span>
                  </div>
                </div>
                <div class="qty">
                  <div class="prod-qty col-4">
                    <div class="qty-choose" style="width: 90px;" @click="toggleChooseQty()">
                      <span class="number">Taglia</span>
                      <span class="fa fa-chevron-down"></span>
                      <div :class="'choose-tent ' + (showTent ? 'open' : '')" v-if="showTent">
                        <div @click="setQty(itm)" v-for="(itm, k2) in $utils.getMultipleArray(item)" v-bind:key="k2">{{itm}}</div>
                        <div class="nomarg">
                          <input type="number" @click="stopPropoli" @keyup.enter="setQtyCustom" placeholder="Altro" class="pdpdpd" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-4">
                    <div class="add-to-cart" @click="addToCart(item)">
                      aggiungi
                      <img src="@/assets/cart-white.png" class="cart-icon" />
                      <!--<span class="fa fa-shopping-cart"></span>-->
                    </div>
                  </div>
                </div>

              </div>

            </div>
            <div class="item">
              <div class="left">
                <img src="https://shop.altromercato.it/products/00003459_1.jpg?v=2" width="299px"/>
              </div>
              <div class="right">
                <h2 class="neue text-18">Nome del set prodotto abbigliamento</h2>
                <div class="price">
                  <div class="price col-4">
                    <span class="cart-discount" v-if="item.sconto > 0">&euro; {{($utils.getProductPrice(item, true).toFixed(2) + "").replace('.', ',')}} <b style="display: inline-block; margin-left: 5px">-{{item.sconto}}%</b></span>
                    <span class="current">&euro; 43,00</span>
                    <span class="online-price">Prezzo online</span>
                  </div>
                </div>
                <div class="qty">
                  <div class="prod-qty col-4">
                    <div class="qty-choose" @click="toggleChooseQty()">
                      <span class="number">{{currentQty}}</span>
                      <span class="fa fa-chevron-down"></span>
                      <div :class="'choose-tent ' + (showTent ? 'open' : '')" v-if="showTent">
                        <div @click="setQty(itm)" v-for="(itm, k2) in $utils.getMultipleArray(item)" v-bind:key="k2">{{itm}}</div>
                        <div class="nomarg">
                          <input type="number" @click="stopPropoli" @keyup.enter="setQtyCustom" placeholder="Altro" class="pdpdpd" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-4">
                    <div class="add-to-cart" @click="addToCart(item)">
                      aggiungi
                      <img src="@/assets/cart-white.png" class="cart-icon" />
                      <!--<span class="fa fa-shopping-cart"></span>-->
                    </div>
                  </div>
                </div>

              </div>

            </div>
            <div class="item">
              <div class="left">
                <img src="https://shop.altromercato.it/products/00003459_1.jpg?v=2" width="299px"/>
              </div>
              <div class="right">
                <h2 class="neue text-18">Nome del set prodotto abbigliamento</h2>
                <div class="price">
                  <div class="price col-4">
                    <span class="cart-discount" v-if="item.sconto > 0">&euro; {{($utils.getProductPrice(item, true).toFixed(2) + "").replace('.', ',')}} <b style="display: inline-block; margin-left: 5px">-{{item.sconto}}%</b></span>
                    <span class="current">&euro; 43,00</span>
                    <span class="online-price">Prezzo online</span>
                  </div>
                </div>
                <div class="qty">
                  <div class="prod-qty col-4">
                    <div class="qty-choose" @click="toggleChooseQty()">
                      <span class="number">{{currentQty}}</span>
                      <span class="fa fa-chevron-down"></span>
                      <div :class="'choose-tent ' + (showTent ? 'open' : '')" v-if="showTent">
                        <div @click="setQty(itm)" v-for="(itm, k2) in $utils.getMultipleArray(item)" v-bind:key="k2">{{itm}}</div>
                        <div class="nomarg">
                          <input type="number" @click="stopPropoli" @keyup.enter="setQtyCustom" placeholder="Altro" class="pdpdpd" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-4">
                    <div class="add-to-cart" @click="addToCart(item)">
                      aggiungi
                      <img src="@/assets/cart-white.png" class="cart-icon" />
                      <!--<span class="fa fa-shopping-cart"></span>-->
                    </div>
                  </div>
                </div>

              </div>

            </div>
            <div class="item">
              <div class="left">
                <img src="https://shop.altromercato.it/products/00003459_1.jpg?v=2" width="299px"/>
              </div>
              <div class="right">
                <h2 class="neue text-18">Nome del set prodotto abbigliamento</h2>
                <div class="price">
                  <div class="price col-4">
                    <span class="cart-discount" v-if="item.sconto > 0">&euro; {{($utils.getProductPrice(item, true).toFixed(2) + "").replace('.', ',')}} <b style="display: inline-block; margin-left: 5px">-{{item.sconto}}%</b></span>
                    <span class="current">&euro; 43,00</span>
                    <span class="online-price">Prezzo online</span>
                  </div>
                </div>
                <div class="qty">
                  <div class="prod-qty col-4">
                    <div class="qty-choose" @click="toggleChooseQty()">
                      <span class="number">{{currentQty}}</span>
                      <span class="fa fa-chevron-down"></span>
                      <div :class="'choose-tent ' + (showTent ? 'open' : '')" v-if="showTent">
                        <div @click="setQty(itm)" v-for="(itm, k2) in $utils.getMultipleArray(item)" v-bind:key="k2">{{itm}}</div>
                        <div class="nomarg">
                          <input type="number" @click="stopPropoli" @keyup.enter="setQtyCustom" placeholder="Altro" class="pdpdpd" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-4">
                    <div class="add-to-cart" @click="addToCart(item)">
                      aggiungi
                      <img src="@/assets/cart-white.png" class="cart-icon" />
                      <!--<span class="fa fa-shopping-cart"></span>-->
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs";
//import ProductSlider from "@/components/ProductSlider";
//import axios from "axios";

//import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "ShopByLook",
  components:{
    Breadcrumbs
  },
  data(){
    return{
      path: [
        { label: 'Home', path: '/' },
        { label: 'Shop By Look', path: '/shopbylook' },
      ],

      badSuppliers: ['M361', 'AQ', 'EQ', '9816334', '9815390'],
      suppliersEthical: ['M361', 'AQ', 'EQ', '9815390'],
      suppliersUpcycling: ['9816334'],

      accordion: [],
      item: false,
      suppliers: [],
      images: [],
      currentQty: 1,
      showTent: false,
      taglie: [],
      childKey: 1,
      settings: {
        "arrows": true,
        "dots": true,
        "infinite": false,
        "speed": 500,
        "slidesToShow": 1,
        "slidesToScroll": 1,
        "swipeToSlide": false,
        "touchThreshold": 2
      },
      destinatari: [
        {email: 'asdfgsfgdfg', name: 'ciao'},
        {email: '', name: ''},
        {email: '', name: ''},
        {email: '', name: ''},
        {email: '', name: ''},
        {email: '', name: ''},
        {email: '', name: ''},
        {email: '', name: ''},
        {email: '', name: ''},
        {email: '', name: ''}
      ],
      componentKey: 0
    }
  },

  watch: {
    $route() {
      //this.initPage()
      this.childKey++
    }
  },

  async created () {
   // await this.initPage()
  },

  computed: {
    isAlcool: function () {
      let alc = false

      if (!this.item.categories) {
        return alc
      }

      this.item.categories.forEach((c) => {
        if (c.categoryCode == 'EC81500') {
          alc = true
        }
      })

      return alc
    },
    qtys: function () {
      let res = []
      for (let index = 1; index <= this.currentQty; index++) {
        res.push(index)
      }

      return res
    },
    hasDestinatari: function () {
      if (!this.$utils.isGift(this.item.itemNumber)) {
        return true
      }

      let chiesa = true

      this.qtys.forEach((item) => {
        if (!this.destinatari[item - 1].name.length || !this.destinatari[item - 1].email.length) {
          chiesa = false
        }
      })

      return chiesa
    },

    singleEmails: function () {
      let emails = [],
          checkEmails = []
      this.destinatari.forEach((item) => {
        if (item.email.length && !checkEmails.includes(item.email)) {
          emails.push({email: item.email, message: ''})
          checkEmails.push(item.email);
        }
      })

      return emails
    }
  },

  methods: {
    toggleAccordion(id) {
      this.accordion[id].open = !this.accordion[id].open
    },

    stopPropoli (evt) {
      evt.stopPropagation()
    },

    setQtyCustom (evt) {
      let qty = parseInt(evt.target.value)
      if(isNaN(qty) || !isFinite(qty) || (qty < 1)) {
        qty = 1
      }

      if (qty > this.item.stock) {
        qty = this.item.stock
      }

      let multiplo = parseInt(this.item.multiplo)

      if (multiplo > 1 && (qty % multiplo != 0)) {
        qty = qty - (qty % multiplo) + multiplo
      }

      this.currentQty = qty
      this.showTent = !this.showTent
      evt.target.value = ''
    },

    /*async initPage () {
      try {
        this.taglie = []
        this.images = []
        this.accordion = []
        this.destinatari = [
          {email: '', name: ''},
          {email: '', name: ''},
          {email: '', name: ''},
          {email: '', name: ''},
          {email: '', name: ''},
          {email: '', name: ''},
          {email: '', name: ''},
          {email: '', name: ''},
          {email: '', name: ''},
          {email: '', name: ''}
        ]

        let allImages = await axios.get('https://shop.altromercato.it/products/imgs.php')
        this.item = this.$utils.getProduct(this.$route.params.code)

        if (!this.item || !this.item.visible) {
          this.$router.push('/')
        }

        this.currentQty = this.item.multiplo ? this.item.multiplo : 1
        this.path = [{
          label: 'Home',
          path: '/'
        }].concat(this.$utils.getCategoryPath(this.item.productCategory).reverse()).concat([{
          label: this.$utils.capitalize(this.item.productName),
          path: ''
        }])

        if (this.$utils.isGift(this.item.itemNumber)) {
          let parts = parseInt(this.item.productName.split(' ')[2])
          this.item.productPrice = parts
          this.item.stock = 5000
        }

        allImages.data.forEach((item) => {

          if (this.item.masterCode) {
            if (item.indexOf(this.item.masterCode + '_') == 0) {
              this.images.push(item)
            }
          } else {
            if (item.indexOf(this.$route.params.code + '_') == 0) {
              this.images.push(item)
            }
          }
        })


        if (this.item.masterCode) {
          let itemTaglie = this.$utils.getByMastercode(this.item.masterCode)
          let tutteTaglie = ['XS', 'S', 'M', 'L', 'XL', 'S/M', 'L/XL']

          if (this.$utils.isGift(this.item.itemNumber)) {
            tutteTaglie = ['20€', '50€', '100€', '150€', '200€']
          }

          tutteTaglie.forEach((size) => {
            itemTaglie.forEach((itm) => {
              if (itm.measure == size) {
                this.taglie.push({
                  label: itm.measure,
                  active: itm.measure == this.item.measure,
                  itemNumber: itm.itemNumber
                })
              }
            })
          })
        }

        if (this.item.fairTradeSuppliers && this.item.fairTradeSuppliers[0]) {
          this.suppliers = this.$utils.getSuppliers(this.item.fairTradeSuppliers)
        }

        if (this.item.legalName && this.item.legalName.length) {
          this.accordion.push({
            content: this.item.legalName,
            label: 'Denominazione legale',
            open: false
          })
        }

        if (this.item.nutritionalFacts.length) {
          let html = '<table class="table-nutri">'

          html += '<tr class="header-nutri"><td>Elemento</td><td>Per 100g</td>'

          if (this.item.nutritionalFacts[0].dailyValueForServingSize) {
            html += '<td>%AR per porzione</td>'
          }

          if (this.item.nutritionalFacts[0].amountForServingSize) {
            html += '<td>Quantità per porzione'
            if (this.item.nutritionalFacts[0].servingSize) {
              html += '<br>' + this.item.nutritionalFacts[0].servingSize
            }
            html += '</td>'
          }

          html += '</tr>'

          for (let index in this.item.nutritionalFacts) {
            html += '<tr><td>' + this.item.nutritionalFacts[index].nutritionalValue + '</td><td>' + this.item.nutritionalFacts[index].amountFor100 + '</td>'

            if (this.item.nutritionalFacts[0].dailyValueForServingSize) {
              html += '<td>' + this.item.nutritionalFacts[index].dailyValueForServingSize + '%</td>'
            }

            if (this.item.nutritionalFacts[0].amountForServingSize) {
              html += '<td>' + this.item.nutritionalFacts[index].amountForServingSize + '</td>'
            }

            html += '</tr>'
          }
          html += '</table><p>**AR: Assunzione di riferimento giornaliera di un adulto medio (8400Kj/2000Kcal)</p>'
          this.accordion.push({
            content: html,
            label: 'Valori nutrizionali',
            open: false,
            facts: this.item.nutritionalFacts.length
          })
        }

        let k = 0
        let titles = ['Modalità d\'uso', 'Lista ingredienti', '% di ingredienti di commercio equo', 'Allergeni']

        this.item.extendedData.forEach((ed) => {

          if (ed.length && ed != '0' && k != 2) {
            this.accordion.push({
              open: false,
              label: titles[k],
              content: ed
            })
          }

          k++
        })

        if (this.item.producedBy && this.item.producedBy.length) {
          this.accordion.push({
            content: this.item.producedBy,
            label: 'Prodotto da',
            open: false
          })
        }

        if (this.item.packagingDisposal && this.item.packagingDisposal.length) {
          this.accordion.push({
            content: this.item.packagingDisposal,
            label: 'Smaltimento imballaggio',
            open: false
          })
        }

        // Set google maps for supplier
        setTimeout(() => {

          let index = 0

          this.suppliers.forEach((suppl) => {
            let latLng = {
              lat: parseFloat(suppl.latitudine),
              lng: parseFloat(suppl.longitudine)
            }

            // eslint-disable-next-line no-use-before-define
            if (document.getElementById("supplier-map-" + index)) {
              let map = new window.google.maps.Map(document.getElementById("supplier-map-" + index), {
                center: latLng,
                zoom: 4,
                styles: this.$config.mapStyles,
                zoomControl: false,
                disableDefaultUI: true
              });

              new window.google.maps.Marker({
                position: latLng,
                map,
                icon: '/products/marker.png',
                title: suppl.nome_produttore,
              });
            }

            index++
          })

        }, 500)

        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          'event': 'productClick',
          'ecommerce': {
            'click': {
              'actionField': {'list': 'Product detail page'},      // Optional list property.
              'products': [{
                name: this.item.productName,
                id: this.item.itemNumber,
                price: this.item.productPrice,
                brand: this.item.brand,
                category: this.item.categories[this.item.categories.length - 1].categoryName,
                variant: this.item.fairTradeSuppliers ? this.item.fairTradeSuppliers[0] : ''
               }]
             }
           }
        });

        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          'ecommerce': {
            'detail': {
              'actionField': {'list': 'Apparel Gallery'},
              'products': [{
                name: this.item.productName,
                id: this.item.itemNumber,
                price: this.item.productPrice,
                brand: this.item.brand,
                category: this.item.categories[this.item.categories.length - 1].categoryName,
                variant: this.item.fairTradeSuppliers ? this.item.fairTradeSuppliers[0] : ''
               }]
             }
           }
        });

      } catch (e) {
        console.log(e)
      }
    },
*/
    exchange (id) {
      let tmp = this.images[0]
      this.images[0] = this.images[id + 1]
      this.images[id + 1] = tmp
      this.$forceUpdate()
    },

    addToCart(item) {
      let giftComplete = this.destinatari;

      giftComplete = giftComplete.map((item) => {
        let tempDestinatario = this.singleEmails.filter((item2) => item2.email === item.email)[0]
        if (tempDestinatario) {
          return {
            email: item.email,
            name: item.name,
            message: tempDestinatario.message
          }
        }
      })
      this.$store.commit('addGift', giftComplete.filter((item) => item != null))

      this.$store.commit('addToCart', {
        item,
        qty: this.currentQty,
        giftData: this.qtys.length ? this.destinatari : []
      })

      this.$toast.open('Articolo aggiunto al carrello')
    },

    toggleChooseQty () {

      if (!this.showTent) {

        setTimeout(() => {
          document.querySelector('body').addEventListener('click', () => {
            if (this.showTent) {
              this.showTent = false
            }
          }, { once: true })
        }, 300)

        this.showTent = true
      } else {
        this.showTent = false
      }
    },

    setQty (qty) {
      this.currentQty = qty
    },

    openGuida () {
      this.$modal.show('guida')
    },

    reloadTpl () {
      this.componentKey += 1;
    }
  }
}
</script>

<style>
@media (max-width: 768px){
  .item{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
  }
  .mw-487{
    max-width: 487px;
    padding: 10px;
  }

}
.items{
  margin: 2rem 0 4rem 0;
}
.item{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
}
.item img{
  width: 150px;
}

.price{
  display: flex;
  flex-direction: column;
}
.qty{
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}
.flex{
  display: flex;
}
.flex-col{
  flex-direction: column;
}
.mw-487{
  max-width: 487px;
}
.text-18{
  font-size: 18px!important;
}
.text-small{
  font-size: 15px!important;
  font-family: Avenir;
  line-height: 20px;
}
.text-verySmall{
  font-size: 12px!important;
}
.text-underlined{
  text-decoration: underline;
}
.cursor-pointer{
  cursor: pointer;
}
.text-thin{
  font-weight: lighter!important;
}
.text-right{
  float: right;
}
.text-25{
  max-width: unset!important;
  font-size: 28px!important;
}
.slick-prev,
.slick-next {
  top: 60%;
}
.slick-prev {
  left: 12px;
  z-index: 1;
}
.slick-next {
  right: 10px;
  z-index: 1;
}
.main-image {
  width: 100%;
  border: 1px solid #eee;
}

.product-content {
  padding-left: 30px;
}

.product-content h2 {
  font-size: 33px;
  font-weight: 600;
  max-width: 375px;
  margin-bottom: 20px;
}

.product-content .code {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 12px;
}

.unit {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 65px;
}

.side-images img {
  cursor: pointer;
}

.prod-name-mobi {
  font-size: 28px;
  text-align: center;
  margin-bottom: 15px;
  padding: 0 10px;
}

.little-name-mobi {
  font-size: 18px !important;
  margin: 10px 0 5px 0;
}

.product-content {
  padding: 0 10px;
}

.selling-points {
  margin: 0;
  padding-left: 15px;
  font-size: 16px;
  font-weight: 500;
  margin-top: 35px;
}

.obj-marks {
  position: relative;
}

.obj-marks .marks {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 40px;
}

.obj-marks .marks img {
  width: 40px;
}

.cart-discount {
  display: inline-block;
  padding: 2px 5px;
  color: #fff;
  font-family: neue-haas-grotesk-display, sans-serif;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 3px;
  background-color: #f09049;
}

.little-shipping {
  font-size: 12px;
  letter-spacing: 0.4px;
}

.supplier-map {
  width: 100%;
  height: 200px;
  margin-bottom: 10px;
}

.supplier-name {
  text-transform: uppercase;
  font-weight: bold;
  font-family: neue-haas-grotesk-display, sans-serif;
}

.taglia {
  display: inline-block;
  border: 1px solid #ccc;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  /*padding: 0 10px;*/
  margin-right: 10px;
  margin-bottom: 30px;
  margin-top: 20px;
  border-radius: 50%;
  color: #ccc;
}

.taglia:hover,
.taglia.active {
  border-color: #555;
  color: #555;
}

.pdpdpd {
  width: 100%;
  height: 100%;
  border: 0;
}

.nomarg {
  margin: 0 !important;
  padding: 0 !important;
}

.guida-alle-taglie {
  font-size: 13px;
  cursor: pointer;
  text-decoration: underline;
  margin-bottom: 30px;
  display: inline-block;
  margin-left: 40px;
}

.modal-spacer {
  width: 100%;
  height: 100%;
  padding: 15px;
}

.table-guida {
  width: 100%;
  font-size: 14px;
}

.table-guida th {
  text-align: left;
  padding-bottom: 10px;
}

.table-guida td {
  padding-bottom: 5px;
}

.impact-description {
  font-size: 13px;
  margin-bottom: 2px;
  margin-top: 40px;
}

.impact-title {
  margin: 7px 0 15px 0;
}

.logo-bad {
  width: 100%;
  height: 200px;
  margin-bottom: 10px;
  object-fit: contain;
}

@media (max-width: 680px) {
}
</style>
